import React, {Component} from "react";
import {Button, Grid, Header, Image, Modal} from "semantic-ui-react";
import {connect} from "react-redux";
import {rejectIfAnyNull} from "../controllers/utilities/env-checks";
import AndroidComm from "../controllers/utilities/unity-android-communication";
import {formatQuery} from "../controllers/utilities/string-utils";
import {getAdItem, setAdItem, getAdItemByEventId, ImgVideo} from "../components/lib/ad-manage-api-lib";
import KaisakuApi from "../controllers/kaisaku-api";
import {navigate} from "../../.cache/gatsby-browser-entry";
import {getUA} from "react-device-detect";
import {AdIosaregionSwipetoslide} from "../components/react-slick/ad-iosaregion-swipetoslide";
import "../styles/bg.css";
import zdlbg from "../images/main/zdlbg.png";

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings,
    };
})(class extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            userId:'',
            gameId:'',
            eventId: '',
            accessToken: '',
            nickname:'',
            createdAt:'',
            expireAt:'',
            expireAt_TTL:'',
            divStyle: '',
            adItem: {
                success: false,
                adId:'',
                eventId:'',
                adItem1: {
                    adItems:[]
                },
                adImage:'',
                adHeight:'',
                adUrl:'',
                adAImage:'',
                adAUrl:'',
                adBImage:'',
                adBUrl:'',
                adCImage:'',
                adCUrl:''
            }
        };
        this.state.divStyle = {
            width: '289px',
            borderRadius: '10px 10px 0px 0px',
            display: ''
        };
        let { eventId, userId ,gameId, accessToken, nickname, createdAt, expireAt, expireAt_TTL} = formatQuery(this.props.location.search);
        this.state.userId = userId;
        this.state.gameId = gameId;
        this.state.accessToken = accessToken;
        this.state.nickname = nickname;
        this.state.createdAt = createdAt;
        this.state.expireAt = expireAt;
        this.state.expireAt_TTL = expireAt_TTL;
        if (eventId === undefined) eventId = "";
        this.state.eventId = eventId;
        console.log(this.props.location.search);
        console.log("123");
        console.log(eventId);
        // rejectIfAnyNull(this.props.session, this.props.session);
        this.state.expireTimer = setTimeout(() => {
            clearInterval(this.state.expireTimer);
            AndroidComm.sendSession({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt,expireAt_TTL: this.state.expireAt_TTL});
            console.log("到时间了,直接进入游戏");
        }, 3000);
    }

    async showAd(sType, Url) {
        if (Url !== "") {
            const url = Url + "&rr1=download";
            //const uuid = Buffer.from(url).toString('base64');
            const uuid = encodeURIComponent(Buffer.from(url).toString('base64'));
            console.log(uuid);
            const newurl = `/gotoadios/?eventId=${this.state.adItem.eventId}&gameId=${this.state.gameId}&accessToken=${this.state.accessToken}&userId=${this.state.userId}&nickname=${this.state.nickname}&createdAt=${this.state.createdAt}&expireAt=${this.state.expireAt}&expireAt_TTL=${this.state.expireAt_TTL}&uuid=${uuid}&r1=download`;
            console.log(newurl);
            window.location.href = url;
            // setAdItem(this.state.userId, this.state.adItem.eventId, sType);
        }
    }

    async closeAd() {
        if (this.state.expireTimer) {
            clearInterval(this.state.expireTimer);
            this.state.expireTimer = undefined;
            console.log("取消定时器");
        }
        // setAdItem(this.state.userId,this.state.adItem.eventId,"");
    }

    async componentDidMount(){
        if (this.state.adItem.success === false) {
            let getdate = false;
            if (this.state.eventId !== ""){
                this.state.adItem = await getAdItemByEventId(this.state.eventId);
                getdate = this.state.adItem.success;
            }
            if ((getdate === false) && (this.state.gameId)){
                console.log("获取IOS广告");
                this.state.adItem = await getAdItem(this.state.userId, 0, 1, this.state.gameId);
            }
            this.state.adItem.adHeight = '434px';
            const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

            if (this.state.adItem.adItem1.adImage === undefined){
                this.state.adItem.success = false;
            }else{
                if ((this.state.gameId === "8595e5c6-9282-4197-a54e-dd192ec13409")
                    // || (this.state.gameId === "d074e9aa-ab68-447f-9c69-ac6f04191cf3")
                    || (this.state.gameId === "d2fd2589-0e94-4323-ac21-ba52b63d7149")
                    || (this.state.gameId === "72153dd5-1681-4f73-a581-c0c4e1ffe21a")
                    || (this.state.gameId === "f9914787-12db-423d-9695-f1c95432d56a")
                    || (this.state.gameId === "97faa45e-795e-4590-bb8d-86a2f8473739")
                    || (this.state.gameId === "3c7538c3-2478-4e2a-af82-f49445e96c6f")
                    || (this.state.gameId === "98b345ba-79b6-4209-88ff-87ba865ddf54")
                    || (this.state.gameId === "0b78cded-f7ef-4ba1-a32b-d22c1e001a97")
                    || (this.state.gameId === "0ca86e6d-d223-4b76-98af-967a52b09323")
                ){
                    const list = this.state.adItem.adItem1.adImage.split("|");
                    console.log(list);
                    if (list.length >= 2) {
                        this.state.adItem.adItem1.adImage = list[1];
                        let wh = width;
                        if (height < wh) wh = height;
                        console.log(wh);
                        if (wh < 375) {
                            this.state.adItem.adHeight = '238px';
                        }else{
                            this.state.adItem.adHeight = '289px';
                        }
                    }
                }else{
                    let wh = width;
                    if (height > wh) wh = height;
                    if (wh < 510) {
                        this.state.adItem.adHeight = '400px';
                    }
                }
            }
        }
        if (this.state.adItem.success === false){
            AndroidComm.sendSession({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt,expireAt_TTL: this.state.expireAt_TTL});
        }else{
            if (this.state.expireTimer) {
                clearInterval(this.state.expireTimer);
                this.state.expireTimer = undefined;
                console.log("取消定时器");
            }
            this.setState({
                divStyle: {
                    width: '289px',
                    borderRadius: '10px 10px 0px 0px',
                    display: ''
                }
            });
        }
    }

    render() {
        this.closeAd();
        navigate(`/unionpay-page1/${this.props.location.search}&re=4`);
        let mode = "PORTRAIT";
        this.state.adItem.adHeight = '434px';
        if ((this.state.gameId === "8595e5c6-9282-4197-a54e-dd192ec13409")
            // || (this.state.gameId === "d074e9aa-ab68-447f-9c69-ac6f04191cf3")
            || (this.state.gameId === "d2fd2589-0e94-4323-ac21-ba52b63d7149")
            || (this.state.gameId === "72153dd5-1681-4f73-a581-c0c4e1ffe21a")
            || (this.state.gameId === "f9914787-12db-423d-9695-f1c95432d56a")
            || (this.state.gameId === "97faa45e-795e-4590-bb8d-86a2f8473739")
            || (this.state.gameId === "3c7538c3-2478-4e2a-af82-f49445e96c6f")
            || (this.state.gameId === "98b345ba-79b6-4209-88ff-87ba865ddf54")
            || (this.state.gameId === "0b78cded-f7ef-4ba1-a32b-d22c1e001a97")
            || (this.state.gameId === "0ca86e6d-d223-4b76-98af-967a52b09323")
        ) {
            // || (this.state.gameId === "d074e9aa-ab68-447f-9c69-ac6f04191cf3")
            const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            let wh = width;
            if (height < wh) wh = height;
            console.log(wh);
            if (wh < 375) {
                this.state.adItem.adHeight = '238px';
            } else {
                this.state.adItem.adHeight = '289px';
            }
            mode = "LANDSCAPE";
        }else{
            if (this.state.gameId) {
                const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
                const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
                let wh = width;
                if (height > wh) wh = height;
                if (wh < 510) {
                    this.state.adItem.adHeight = '380px';
                }
            }
        }
        return (
            <Grid textAlign='center' style={{
                height: "100vh",
                background: "transparent",
                padding: "3vh"
            }} verticalAlign='middle'>
                <Grid.Column style={{maxWidth: 450}}>
                    <Modal
                        style={{width:'289px',borderRadius:'10px 10px 10px 10px'}}
                        open
                    >
                        <div style={this.state.divStyle}>
                            <div style={{height:'45px',textAlign:'center',fontSize:'14px',fontWeight:'bold',
                                paddingTop:'12px',border:'0px',
                                borderRadius:'0px 0px 10px 10px',
                                backgroundImage: `url(${zdlbg})` , backgroundSize:'100% 100%',
                                marginTop:"-3px",
                                marginLeft:"-8px",
                                marginRight:"-8px",
                                marginBottom:"-10px",
                                color:"#FFF",
                                opacity:'1'}}
                                 onClick={async () => {
                                    this.closeAd();
                                    navigate(`/unionpay-page1/${this.props.location.search}&re=4`);
                                    //  const res = await KaisakuApi.getNoodleInfo(this.state.gameId, this.state.accessToken);
                                    //  if ((res.data.success) || ((res.data.loginData) && (res.data.loginData.showInfo))) {
                                    //     if (res.data.success) {
                                    //         if (res.data.cardtype) {
                                    //             res.data.index = res.data.cardtype;
                                    //         }           
                                    //         navigate(`/sdk-ad-noodle-entr/?showInfo=${res.data.loginData.showInfo}&monthCount=${res.data.loginData.monthCount}&allcount=${res.data.loginData.allcount}&url=${res.data.url}&index=${res.data.index}&startTime=${res.data.startTime}&endTime=${res.data.endTime}&attributionId=${res.data.attributionId}&transactionId=${res.data.transactionId}&eventId=${this.state.eventId}&gameId=${this.state.gameId}&accessToken=${this.state.accessToken}&userId=${this.state.userId}&nickname=${this.state.nickname}&createdAt=${this.state.createdAt}&expireAt=${this.state.expireAt}`);
                                    //     } else {
                                    //         navigate(`/sdk-ad-noodle-entronly/?showInfo=${res.data.loginData.showInfo}&monthCount=${res.data.loginData.monthCount}&allcount=${res.data.loginData.allcount}&startTime=${res.data.startTime}&endTime=${res.data.endTime}&attributionId=${res.data.attributionId}&transactionId=${res.data.transactionId}&eventId=${this.state.eventId}&gameId=${this.state.gameId}&accessToken=${this.state.accessToken}&userId=${this.state.userId}&nickname=${this.state.nickname}&createdAt=${this.state.createdAt}&expireAt=${this.state.expireAt}`);
                                    //     }
                                    //     // if (res.data.url){
                                    //     //     if (res.data.cardtype){
                                    //     //         res.data.index = res.data.cardtype;
                                    //     //     }
                                    //     //     navigate(`/${res.data.url}/?index=${res.data.index}&startTime=${res.data.startTime}&endTime=${res.data.endTime}&attributionId=${res.data.attributionId}&transactionId=${res.data.transactionId}&eventId=${this.state.eventId}&gameId=${this.state.gameId}&accessToken=${this.state.accessToken}&userId=${this.state.userId}&nickname=${this.state.nickname}&createdAt=${this.state.createdAt}&expireAt=${this.state.expireAt}`);
                                    //     // }else{
                                    //     //     navigate(`/sdk-ad-noodle/?startTime=${res.data.startTime}&endTime=${res.data.endTime}&attributionId=${res.data.attributionId}&transactionId=${res.data.transactionId}&eventId=${this.state.eventId}&gameId=${this.state.gameId}&accessToken=${this.state.accessToken}&userId=${this.state.userId}&nickname=${this.state.nickname}&createdAt=${this.state.createdAt}&expireAt=${this.state.expireAt}`);
                                    //     // }
                                    //  }else{
                                    //     this.closeAd();
                                    //     // console.log({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt });
                                    //     AndroidComm.sendSession({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt, expireAt_TTL: this.state.expireAt_TTL });   
                                    //  }
                                 }}
                            >
                                <span style={{color:'#FFF'}}>請稍後,加載中...</span>
                            </div>
                        </div>
                    </Modal>
                </Grid.Column>
            </Grid>
        );
    }
});